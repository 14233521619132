function addResizeListener(gantt){
	var containerStyles = window.getComputedStyle(gantt.$root);
	if(containerStyles.getPropertyValue("position") == "static"){
		gantt.$root.style.position = "relative";
	}

	var resizeWatcher = document.createElement('iframe');
	resizeWatcher.className = "gantt_container_resize_watcher";
	resizeWatcher.tabIndex = -1;
	if(gantt.config.wai_aria_attributes){
		resizeWatcher.setAttribute("role", "none");
		resizeWatcher.setAttribute("aria-hidden", true);
	}

	// in some environments (namely, in SalesForce) iframe.contentWindow is not available
	gantt.$root.appendChild(resizeWatcher);
	if (resizeWatcher.contentWindow) {
		listenWindowResize(gantt, resizeWatcher.contentWindow);
	} else {
		// if so - ditch the iframe and fallback to listening the main window resize
		gantt.$root.removeChild(resizeWatcher);
		listenWindowResize(gantt, window);
	}
}

function listenWindowResize(gantt, window){
	var resizeDelay;
	gantt.event(window, "resize", function(){
		clearTimeout(resizeDelay);
		resizeDelay = setTimeout(function(){
			gantt.render();
		}, 300);
	});
}

module.exports = addResizeListener;