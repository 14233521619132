var domHelpers = require("./ui/utils/dom_helpers"),
	helpers = require("../utils/helpers");
var isHeadless = require("../utils/is_headless");
var addResizeListener = require("./ui/resize_listener");

module.exports = function(gantt){
	var calculateScaleRange = require("./gantt_data_range");

	gantt.assert = require("./common/assert")(gantt);

//initial initialization
	gantt.init = function(node, from, to){
		if(from && to){
			this.config.start_date = this._min_date = new Date(from);
			this.config.end_date = this._max_date = new Date(to);
		}
		this.date.init();

		//can be called only once
		this.init = function(node){
			if (this.$container && this.$container.parentNode){
				this.$container.parentNode.removeChild(this.$container);
				this.$container = null;
			}

			if(this.$layout){
				this.$layout.clear();
			}
			this._reinit(node);
		};

		this._reinit(node);
	};


	var dropLayout = (function dropLayout(){
		if(this._clearTaskLayers){
			this._clearTaskLayers();
		}

		if(this._clearLinkLayers){
			this._clearLinkLayers();
		}

		if(this.$layout){
			this.$layout.destructor();
			this.$layout = null;
			this.$ui.reset();
		}
	}).bind(gantt);

	var rebuildLayout = (function rebuildLayout(){
		if(isHeadless(gantt)){
			return;
		}

		this.$root.innerHTML = "";
		
		this.$root.gantt = this;
		calculateScaleRange(this);
		this.config.layout.id = "main";
		this.$layout = this.$ui.createView("layout", this.$root, this.config.layout);

		this.$layout.attachEvent("onBeforeResize", function(){
			var storeNames = gantt.$services.getService("datastores");
			for(var i = 0; i < storeNames.length; i++){
				gantt.getDatastore(storeNames[i]).filter();
			}
		});

		this.$layout.attachEvent("onResize", function(){
			gantt.refreshData();
		});

		this.callEvent("onGanttLayoutReady", []);
		this.$layout.render();

		this.$container = this.$layout.$container.firstChild;

		addResizeListener(this);
	}).bind(gantt);

	gantt.resetLayout = function(){
		dropLayout();
		rebuildLayout();
		this.render();
	};

	gantt._reinit = function(node){
		this.callEvent("onBeforeGanttReady", []);
		this._update_flags();

		var config = this.$services.getService("templateLoader");
		config.initTemplates(this);

		dropLayout();

		if(node){
			this.$root = domHelpers.toNode(node);
			rebuildLayout();
			this.$mouseEvents.reset(this.$root);
		}

		this.callEvent("onTemplatesReady",[]);
	
		this.callEvent("onGanttReady", []);

		this.render();
	};

	gantt.$click={
		buttons:{
			"edit": function(id) {
				if (gantt.isReadonly(gantt.getTask(id))) {
					return;
				}
				gantt.showLightbox(id);
			},
			"delete": function(id) {
				var task = gantt.getTask(id);
				if (gantt.isReadonly(task)) {
					return;
				}
				var question = gantt.locale.labels.confirm_deleting;
				var title = gantt.locale.labels.confirm_deleting_title;

				gantt._dhtmlx_confirm(question, title, function(){
					if(!gantt.isTaskExists(id)){
						gantt.hideLightbox();
						return;
					}

					if(task.$new){
						gantt.silent(function(){
							gantt.deleteTask(id, true);
						});
						gantt.refreshData();
					}else{
						gantt.deleteTask(id);
					}

					gantt.hideLightbox();
				});
			}
		}
	};

//renders self
	gantt.render = function(){
		this.callEvent("onBeforeGanttRender", []);

		var visibleDate;
		if(!isHeadless(gantt)){
			if (!this.config.sort && this._sort) {
				this._sort = undefined;
			}

			if(this.$root){
				if(this.config.rtl){
					this.$root.classList.add("gantt_rtl");
				}else{
					this.$root.classList.remove("gantt_rtl");
				}
			}

			var pos = this.getScrollState();
			var posX = pos ? pos.x : 0;
			if(this._getHorizontalScrollbar()){
				var scrollbar = this._getHorizontalScrollbar();
				posX = scrollbar.$config.codeScrollLeft || posX || 0;
			}


			visibleDate = null;
			if(posX){
				visibleDate = gantt.dateFromPos(posX + this.config.task_scroll_offset);
			}
		}

		calculateScaleRange(this);

		if(!isHeadless(gantt)){
			this.$layout.$config.autosize = this.config.autosize;
			this.$layout.resize();

			if(this.config.preserve_scroll && pos){

				if(posX){
					var new_pos = gantt.getScrollState();
					var new_date = gantt.dateFromPos(new_pos.x);
					if(!(+visibleDate == +new_date && new_pos.y == pos.y)){
						if(visibleDate){
							this.showDate(visibleDate);
						}
						if(pos.y)
							gantt.scrollTo(undefined, pos.y);
					}
				}
			}

		}else{
			gantt.refreshData();
		}
		this.callEvent("onGanttRender", []);
	};

	//TODO: add layout.resize method that wouldn't trigger data repaint
	gantt.setSizes = gantt.render;


	gantt.getTaskRowNode = function(id) {
		var els = this.$grid_data.childNodes;
		var attribute = this.config.task_attribute;
		for (var i = 0; i < els.length; i++) {
			if (els[i].getAttribute) {
				var value = els[i].getAttribute(attribute);
				if (value == id) return els[i];
			}
		}
		return null;
	};

	gantt.changeLightboxType = function(type){
		if(this.getLightboxType() == type)
			return true;
		gantt._silent_redraw_lightbox(type);
	};

	gantt._get_link_type = function (from_start, to_start) {
		var type = null;
		if (from_start && to_start) {
			type = gantt.config.links.start_to_start;
		} else if (!from_start && to_start) {
			type = gantt.config.links.finish_to_start;
		} else if (!from_start && !to_start) {
			type = gantt.config.links.finish_to_finish;
		} else if (from_start && !to_start) {
			type = gantt.config.links.start_to_finish;
		}
		return type;
	};

	gantt.isLinkAllowed = function (from, to, from_start, to_start) {
		var link = null;
		if (typeof(from) == "object") {
			link = from;
		} else {
			link = {source: from, target: to, type: this._get_link_type(from_start, to_start)};
		}

		if (!link) return false;
		if (!(link.source && link.target && link.type)) return false;
		if (link.source == link.target) return false;

		var res = true;
		//any custom rules
		if (this.checkEvent("onLinkValidation"))
			res = this.callEvent("onLinkValidation", [link]);

		return res;
	};


	gantt._correct_dst_change = function(date, prevOffset, step, unit){
		var time_unit = helpers.getSecondsInUnit(unit) * step;
		if(time_unit > 60*60 && time_unit < 60*60*24){
			//correct dst change only if current unit is more than one hour and less than day (days have own checking), e.g. 12h
			var offsetChanged = date.getTimezoneOffset() - prevOffset;
			if(offsetChanged){
				date = gantt.date.add(date, offsetChanged, "minute");
			}
		}
		return date;
	};

	gantt.isSplitTask = function(task){
		gantt.assert(task && task instanceof Object, "Invalid argument <b>task</b>="+task+" of gantt.isSplitTask. Task object was expected");
		return this.$data.tasksStore._isSplitItem(task);
	};

	gantt._is_icon_open_click = function(e) {
		if (!e)
			return false;
		var target = e.target || e.srcElement;
		if (!(target && target.className))
			return false;
		var className = domHelpers.getClassName(target);
		if (className.indexOf("gantt_tree_icon") !== -1 && (className.indexOf("gantt_close") !== -1 || className.indexOf("gantt_open") !== -1))
			return true;
		return false;
	};

};